.wrapper {
    display: grid;
    grid-template-columns: 250px 1fr;
}

.right-div {
    background-color: #e5e5e5;
    padding: 2rem 3rem;
    min-height: 100vh;

    height: 100%;
}
@media screen and (max-width: 767px) {
    .wrapper {
        grid-template-columns: 60px 1fr;
    }
}
