.heading {
    font-size: 1.5rem;
    color: #1a1717;
    font-weight: 600;
    font-family: "Lato";
}
.block-btns {
    display: flex;
    gap: 0.3rem;
}

.block-btns button {
    color: white;
    font-size: 14px;
    border: none;
    font-weight: 550;
    cursor: pointer;
    width: fit-content;
    padding: 0.2rem 0.4rem;
    font-family: Open Sans, sans-serif;
    border-radius: 5px;
}
.green-btn {
    background-color: #28a745;
}
.red-btn {
    background-color: #dc3545;
}
.loader {
    /* margin-left: 15rem; */
    display: grid;
    align-items: center;
    justify-items: center;
}
.search-download {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.download {
    cursor: pointer;
    background-color: #2e3346;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    /* height: fit-content; */
    font-size: 1rem;
    padding: 0.6rem 2rem;
    color: white;
    outline: none;
    border: none;
}
.search-form {
    width: 100%;
    margin: 1rem 0;
    display: flex;
}
.search-form input {
    width: 25rem;
    height: 2.8rem;
    text-indent: 1.6rem;
    font-size: 1.2rem;
    outline: none;
    font-family: "Lato";
}
.search-form div {
    position: relative;
}
.search-form button {
    cursor: pointer;
    background-color: #2e3346;

    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    /* height: fit-content; */
    font-size: 1rem;
    padding: 0.6rem 2rem;
    color: white;
    outline: none;
    border: none;
}
.search-icon {
    position: absolute;
    left: 0.3rem;
    top: 0.7rem;
}
.search input {
    width: 60%;
    height: 3rem;
    text-indent: 1.6rem;
    font-size: 1.3rem;
    outline: none;
    font-family: "Lato";
}
.search button {
    display: flex;
    font-family: "Lato";
    align-items: center;
    gap: 1rem;
    border: 1px solid lightgrey;
}
.search button p {
    margin: 0;
    font-family: "Lato";
}
.settings-data {
    display: grid;
    gap: 1rem;
    margin-top: 4rem;
}
.settting-detail {
    display: grid;
    gap: 1rem;
}
.settting-detail textarea {
    width: 40%;
    font-size: 1.3rem;
    padding-left: 1rem;
    outline: none;
    font-family: Arial, Helvetica, sans-serif;
    resize: none;
}
.settting-data textarea {
    width: 100%;
    max-width: 500px;
    outline: none;
    resize: none;
    text-indent: 0.5rem;
}
.autopay .settting-data {
    margin: 0.5rem 0 !important;
}
.settting-data {
    display: flex;
    gap: 2rem;
}
.settting-data label {
    width: 14rem;
}
.settting-data input {
    width: 12rem;
    height: 2rem;
    outline: none;
    text-indent: 1rem;
}
.settting-data input::-webkit-outer-spin-button,
.settting-data input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.settting-data input[type="number"] {
    -moz-appearance: textfield;
}
.settting-detail label,
.settting-data label,
.settting-detail-percentage label {
    font-weight: 500;
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
}
.settting-detail-percentage {
    display: flex;
    align-items: center;
    gap: 2rem;
}
.settting-detail-percentage input {
    height: 2.5rem;
    font-size: 1.3rem;
    text-indent: 1rem;
    /* width: 6rem; */
}
.radio-button-wrapper {
    display: flex;
    gap: 3rem;
    align-items: center;
}
.radio-buttons {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}
.radio-buttons input {
    -ms-transform: scale(1.4); /* IE 9 */
    -webkit-transform: scale(1.4); /* Chrome, Safari, Opera */
    transform: scale(1.4);
}
.settting-detail > input,
.radio-button-wrapper > input {
    height: 2.4rem;
    width: 12rem;
    outline: none;
    border: 1.5px solid #6865f0;
    font-size: 1.5rem;
    font-weight: 400;
    text-indent: 0.5rem;
}

.settting-detail > input::-webkit-outer-spin-button,
.radio-button-wrapper > input::-webkit-outer-spin-button,
.settting-detail > input::-webkit-inner-spin-button,
.radio-button-wrapper > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.radio-button-wrapper > input,
.settting-detail > input[type="number"] {
    -moz-appearance: textfield;
}
.settings button {
    align-self: center;
    justify-self: center;
    background-color: #26293a;
    border: none;
    outline: none;
    font-size: 1.3rem;
    border-radius: 10px;
    padding: 0.5rem 3rem;
    color: white;
    cursor: pointer;
    margin-top: 1rem;
}
.dateinputs {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin: 1rem 0;
}
.dateinputs input {
    padding: 0.5rem;
    color: #2e3346;
    border-radius: 10px;
    border: 1px solid #2e3346;
    font-weight: 500;
    font-size: 1rem;
}
.dateinputs input::-webkit-calendar-picker-indicator {
    filter: invert(17%) sepia(31%) saturate(559%) hue-rotate(188deg)
        brightness(92%) contrast(89%);
}
.dateinputs button {
    cursor: pointer;
    background-color: #2e3346;
    border-radius: 10px;
    width: fit-content;
    height: fit-content;
    font-size: 1rem;
    padding: 0.5rem 2rem;
    color: white;
    outline: none;
    border: none;
}
.table {
    display: grid;
}
.table > button,
.user-details > button,
.heading-export button {
    /* background-color: #6865f0; */

    background-color: #26293a;
    color: white;
    width: fit-content;
    justify-self: right;
    border: none;
    font-size: 1.3em;
    border-radius: 10px;
    padding: 0.4rem 1rem;
}
.action-btns button {
    background-color: inherit;
    border: none;
    margin: none;
}
@media screen and (max-width: 767px) {
    .settting-data {
        display: grid;
        gap: 1rem;
    }
    .search {
        display: grid;
        gap: 1rem;
        width: 100%;
        justify-content: unset;
    }
    .search input {
        width: 100%;
    }
    .search button {
        width: fit-content;
        padding: 0.5rem 1rem;
        justify-self: right;
    }
}

/* -------------autopaysettings------------- */
.settings-btns {
    margin: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}
.settings-btns button {
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    border-radius: 16px;
    border: none;
}
.settings-btns .active-btn {
    color: #ffffff;
    background: #6865f0;
}
.subnav {
    display: flex;
    gap: 0.2rem;
    margin: 1rem 0;
}
.subnav button {
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;
    font-size: 1.1rem;
    padding: 0.5rem 1rem;
}
.subnav button:hover {
    background-color: #ccc;
}
.active-nav {
    background-color: #ccc !important;
}
/* ---------------send money------------ */
.virtual-money {
    background-color: white;
    display: grid;
    gap: 1rem;
    margin-right: 3rem;
    border-radius: 10px;
    padding: 2rem;
}
.virtual-money .form-control {
    display: grid;
    grid-template-columns: 25% 1fr;
    gap: 1rem;
}
.virtual-money .form-control input,
.virtual-money select {
    height: 2.5rem;
    font-size: 1.3rem;
    text-indent: 1rem;
    width: 60%;
}
.virtual-money select {
    font-size: 1rem;
    width: 61%;
}
.virtual-money .form-control label {
    font-weight: 500;
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
}
.money-submit,
.load-btn {
    background-color: #6865f0;
    color: white;
    padding: 0.5rem 1rem;
    outline: none;
    font-size: 1.1rem;
    cursor: pointer;
    border: none;
    margin: 1rem 6rem 0 0;
    width: fit-content;
    align-items: center;
    justify-self: center;
    border-radius: 6px;
}
.load-btn {
    background-color: inherit;
    border: none;
}
.virtual-money p {
    color: red;
    margin-top: 0.3rem;
}
@media screen and (max-width: 860px) {
    .virtual-money .form-control {
        grid-template-columns: 1fr;
    }
    .virtual-money .form-control input,
    .virtual-money select {
        width: 90%;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

/* ----------------modal------------------------ */
.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    animation: linear normal forwards;
    animation-name: run;
    animation-duration: 100ms;
    display: flex;
    justify-content: flex-end;
    z-index: 1000;
    transition: 100ms;
}
.modal-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    opacity: 1;
    /* max-width: 700px; */
    overflow: scroll;
    min-width: 700px;
    width: fit-content;
    background: #ffffff;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    font-family: "Lato";
    position: relative;
}
.modal-transaction-details {
    min-width: 570px;
}
.modal-transaction-details .add-items {
    padding: 0;
}
.greyBg {
    background-color: lightgrey;
}
.whitebg {
    background-color: white;
}
.transaciton-detail-items a {
    background: #1fb770;
    width: fit-content;
    color: white;
    padding: 6px 10px;
    border-radius: 15px;
    font-size: 14px;
    text-decoration: none;
    font-weight: 700;
}
.modal-content::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}
/* .modal-content: */
.modal-content-header {
    padding: 1rem 1.5rem;
    font-weight: 500;
    background-color: #f9fafb;
    font-family: "Lato";
    display: flex;
    justify-content: space-between;
}
.modal-content-header h3 {
    font-size: 1.3rem;
}
.modal-content-header p {
    font-size: 0.9rem;
}
.close-btn {
    align-self: center;
    background-color: white;
    border: 1px solid white;
    outline: none;
    color: red !important;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 1);
}
.item-image-upload input::-webkit-outer-spin-button,
.item-image-upload input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.item-image-upload input[type="number"] {
    -moz-appearance: textfield;
}
.add-items {
    display: grid;
    /* height: 100%; */
    gap: 2rem;
    padding: 1rem;
}
.modal-transaction-details .add-items {
    gap: 0.5rem;
}
.modal-transaction-details .add-items img {
    justify-self: center;
    margin-bottom: 1rem;
    border-radius: 50%;
    width: 6rem;
}
.add-items-forms {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3rem 0;
    height: 100%;
}
.add-item {
    display: grid;
    align-items: center;
    justify-items: center;
    gap: 0.5rem;
    width: 100%;
}
.add-item .upload-icon {
    color: #0e9f6e;
    font-size: 2rem;
}
.add-item h5 {
    font-size: 14px;
    margin: 0;
    padding: 0;
    font-family: "Lato";
}
.add-item p {
    color: #9e9e9e;
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
    font-family: "Lato";
}
.item-image-upload label,
.item-tag-input label {
    font-family: "Lato";
    color: #24262d;
    font-size: 14px;
}
.item-image-upload {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    /* padding: 0 1rem; */
}
.transaciton-detail-items {
    padding: 0.5rem 1rem;
    display: grid;
    grid-template-columns: 0.8fr 5% 1fr;
    align-items: center;

    gap: 1rem;
}
.transaciton-detail-items h3,
.transaciton-detail-items p {
    font-family: "Lato";
    color: #24262d;
    font-size: 1.3rem;
    margin: 0;
    padding: 0;
}
.upload-image {
    border: 2px dashed #d5d6d7;
    width: 70%;
    height: 9rem;
    display: grid;
    align-items: center;
    cursor: pointer;
}
.item-image-upload select,
.item-image-upload input {
    height: 3rem;
    text-indent: 1rem;
    outline: none;
    width: 100%;
    height: 3rem;
    font-size: 1rem;
    text-indent: 1rem;
    border-radius: 10px;
    background-color: rgba(229, 231, 235, 0.6);
    outline: none;
    /* margin-left: 1rem; */
    border: 1px solid rgba(229, 231, 235, 1);
}
.item-image-upload textarea {
    text-indent: 1rem;
    outline: none;
    width: 100%;
    padding: 0.5rem 0;
    font-size: 1rem;
    text-indent: 1rem;
    border-radius: 10px;
    background-color: rgba(229, 231, 235, 0.6);
    outline: none;
    resize: none;
    border: 1px solid rgba(229, 231, 235, 1);
}
.item-image-upload select:focus,
.item-image-upload input:focus,
.item-image-upload textarea:focus {
    background-color: white;
}
.error {
    color: rgba(249, 128, 128, 1);
    font-size: 1rem;
    font-weight: 1rem;
    padding-bottom: 0;
    margin-bottom: 0;
}
.item-image-upload .select {
    width: 70%;
}
.item-tag-input {
    display: flex;
    justify-content: space-between;
    margin: 0 1rem;
}
.item-tag {
    width: 69%;
    /* padding: 0.4rem 1rem; */
    border-radius: 10px;
    min-height: 3rem;
    border: 1px solid rgba(229, 231, 235, 1);
}
.item-tag input {
    text-indent: 1rem;
}
.add-btn {
    margin: 0 1rem;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}
.add-btn button {
    width: 100%;
    border: none;
    padding: 1rem 0;
    border-radius: 10px;
    color: red;
}
.add-category-btn {
    background-color: #2e3346;
    color: white !important;
}
.formatter-btns {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
@keyframes run {
    0% {
        right: -10rem;
    }
    100% {
        right: 0rem;
    }
}
@media screen and (max-width: 767px) {
    .modal-content {
        width: 85%;
        min-width: 80%;
    }
    .modal-content-header p {
        font-size: 0.8rem;
    }
    .item-image-upload {
        gap: 0.6rem;
        flex-direction: column;
    }
    .upload-image {
        width: 100%;
    }
    .item-image-upload label,
    .item-tag-input label {
        font-size: 17px;
    }
    .item-image-upload .select {
        width: 100%;
    }
    .add-items {
        gap: 1rem;
    }
    .item-tag-input {
        gap: 1rem;
        flex-direction: column;
    }
    .item-tag {
        width: 100%;
    }
}
/* ----------------user-details--------------------- */
.user-details {
    display: grid;
    gap: 1rem;
    /* justify-items: center; */
    /* min-height: 100vh; */
    height: 100%;
}
.user-details img {
    width: 120px;
    border-radius: 50%;
    margin-bottom: 2rem;
    justify-self: center;
}
.user-data {
    display: grid;
    /* width: 100%; */
    /* width: fit-content; */
    grid-template-columns: 25% 2% 70%;
    gap: 1rem;
    align-items: center;
}
.user-data h3 {
    font-weight: 600;
    font-family: "Lato";
    font-size: 1.3rem;
    margin: 0;
    padding: 0;
}
.user-data-img img {
    max-width: 100px;
    justify-self: unset;
    cursor: pointer;
}

.user-data-img div {
    display: flex;
    gap: 1rem;
}
.user-data-img div button {
    width: fit-content;
    height: fit-content;
    align-self: center;
}
.user-data p {
    padding: 0;
    margin: 0;
    font-size: 1.1rem;
    font-family: "Lato";
}
.verified-export {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.verified-export > button,
.export-loader {
    color: white;
    background-color: #2e3346;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 10px;
}
/* .export-loader {
    border-radius: 10px;
    background-color: #2e3346;
    width: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.3rem;
} */
.export-loader {
    display: flex;
    align-items: center;
    width: 8rem;
}
.payout-nav {
    display: flex;
    gap: 0.5rem;
    padding: 1rem 0;
}
.payout-nav button {
    background-color: inherit;
    border: 1px solid gray;
    font-size: 1rem;
    cursor: pointer;
}
.nav-active {
    color: green;
    border: 1px solid green !important;
}

/* ----------circular loader  -----------*/

.circle-Loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* bottom: -70px; */
    background-color: rgba(0, 0, 0, 0.4);
    display: grid;
    align-items: center;
    justify-items: center;
    z-index: 10;
}
.circle-loader {
    z-index: 10;
    overflow: hidden !important;
}

/* Styles for the toggle switch */
.toggle-switch {
    position: relative;
    width: 50px;
    height: 25px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle-label {
    position: absolute;
    cursor: pointer;
    background-color: #ccc;
    border-radius: 25px;
    width: 100%;
    height: 100%;
    transition: background-color 0.2s;
}

.toggle-label .toggle-button {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 21px;
    height: 21px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.2s;
}

.toggle-switch input:checked + .toggle-label {
    background-color: #4caf50;
}

.toggle-switch input:checked + .toggle-label .toggle-button {
    transform: translateX(25px);
}
