.login-wrapper {
    display: grid;
    grid-template-columns: 32% 68%;
}

.left-div {
    min-height: 100vh;
    height: 100%;
    background: #6865f0;
}
.right-div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    min-height: 100vh;
    height: 100%;
    background-color: #e5e5e5;
    font-family: "Roboto", sans-serif;
    align-items: center;
}
.right-div h3 {
    font-family: inherit;
    font-size: 2.2rem;
    margin: 1rem;
}
.right-div h3 span {
    color: #6865f0;
}
.right-div form {
    display: grid;
    gap: 1rem;
    width: 35rem;
    background: #0021781a;
    padding: 3rem 4rem 2rem 4rem;
    border-radius: 2rem;
}
.form-data {
    display: grid;
    gap: 0.5rem;
    font-family: "Roboto", sans-serif;
}
.form-data label {
    font-size: #1a171717;
    font-family: inherit;
    font-size: 1.2rem;
    font-weight: bold;
}
.input {
    display: grid;
    justify-items: center;
    position: relative;
}
.error {
    color: red;
    margin: 0.5rem 0 0 0;
    justify-self: left;
    border: 0;
    font-weight: bold;
    font-family: inherit;
}
.input input {
    width: 100%;
    height: 2.5rem;
    text-indent: 2.5rem;
    border: none;
    border-radius: 0.4rem;
    font-size: 1.3rem;
    padding-top: 0.2rem;
}
.icon {
    position: absolute;
    left: 0.3rem;
    top: 0.4rem;
    font-size: 1.8em;
    color: #110ddd;
}
.right-div input[type="submit"] {
    background-color: #6865f0;
    border: none;
    height: 2.5rem;
    color: white;
    font-size: 1.4rem;
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    cursor: pointer;
    border-radius: 0.3rem;
}
.right-div input[type="submit"]:disabled {
    background-color: rgba(104, 101, 240, 0.5);
}
@media screen and (max-width: 1100px) {
    .login-wrapper {
        grid-template-columns: 25% 75%;
    }
    .right-div form {
        width: 60%;
    }
}
@media screen and (max-width: 767px) {
    .login-wrapper {
        grid-template-columns: 1fr;
        height: 100%;
    }
    .left-div {
        display: none;
    }
    .form-data input {
        width: 100%;
    }
    .right-div form {
        padding: 1rem;
        width: 90%;
        align-items: center;
    }
}
