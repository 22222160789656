.wrapper {
    background: #2e3346;
    /* width: 100%; */
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    max-width: 250px;
    width: 100%;
    gap: 2rem;
    overflow-y: scroll;
}
.wrapper::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.wrapper::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.wrapper img {
    margin: 1rem 0 0 0;
    align-self: center;
    width: 100px;
}
.navlinks {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.link {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.7rem;
    font-size: 1.1rem;
    font-family: "Lato";
    color: lightgrey;
    padding: 0.3rem 0.9rem;
}

.navlinks a:hover {
    color: white;
}
.link p {
    margin: 0;
}
.link {
    text-decoration: none;
    /* border-left: 6px solid #6865f0; */
}
.ActiveLink {
    background-color: #474e6c;
    border-left: 6px solid #315ac3;
    font-size: 1.1rem;
    color: white;
}
.navlinks button {
    margin-left: 0.5rem;
    background-color: inherit;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    border: none;
    font-size: 1.1rem;
    color: lightgrey;
    cursor: pointer;

    font-family: "Lato";
}
.navlinks button:hover {
    color: white;
}
.navlinks button p {
    margin: 0;
}

@media screen and (max-width: 767px) {
    .wrapper {
        max-width: 60px;
        width: 100%;
    }
    .navlinks a p {
        display: none;
    }
    .wrapper img {
        width: 40px;
    }
    .link {
        padding: 0.5rem;
    }
    .navlinks button {
        margin-left: 0.2rem;
    }
    .navlinks button p {
        display: none;
    }
}
