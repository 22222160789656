.category-table {
 border: 1px solid #e5e7eb;
 overflow: scroll;
 -ms-overflow-style: none; /* IE and Edge */
 scrollbar-width: none; /* Firefox */
 /* display: grid; */
 font-family: "Lato";
}
.category-table::-webkit-scrollbar {
 display: none;
}
.react-bootstrap-table table {
 table-layout: auto;
 overflow: scroll;
 width: 100%;
 min-width: 900px;
}
.react-bootstrap-table table::-webkit-scrollbar {
 display: none;
}
.react-bootstrap-table-pagination {
 --bs-gutter-x:: 0;
}
.table > :not(:first-child) {
 border-top: 0;
}
.react-bootstrap-table table thead {
 border-bottom: 1px solid #e5e7eb !important;
}
.react-bootstrap-table table th {
 /* border: none; */
 background-color: rgba(229, 231, 235, 0.6);
 padding:0.5rem 0.3rem !important ;
 font-family: "Lato";
}
.react-bootstrap-table table tbody tr td {
 color: #24262d !important;
 font-size: 14px;
 font-family: sans-serif;
 /* border: none; */
 padding: 1rem 0.3rem;
 word-wrap: break-word !important;
 text-align: center;
 font-family: "Lato";
}
.react-bootstrap-table table tbody tr {
 background-color: white;
 border: 1px solid #dfe1e5;
 cursor: pointer;
}
.react-bootstrap-table table tbody tr:hover {
 background-color: lightgrey;
}
/* .react-bootstrap-table table tbody tr:hover {
 background-color: rgb(245, 241, 241)

} */

.pagination{
 display: flex;
 justify-content: right;
 width: 100%;
}

/* -----------change color of entire row -------------- */
/* .react-bootstrap-table table tbody .red  td {
 color: orange !important;
}
.react-bootstrap-table table tbody .blue  td {
 color: blue !important;
} */