.DashBoardWrapper {
    background-color: white;
    margin-right: 5rem;
    border-radius: 10px;
    padding: 1rem 2rem 3rem 2rem;
    display: grid;
    gap: 1rem;
}
.dashboard-items {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    gap: 2.5rem;
    margin-right: 1rem;
    text-align: center;
}

.dashboard-item {
    background-color: rgba(104, 101, 240, 0.4);
    width: fit-content;
    display: grid;
    padding: 1rem;
    min-height: 2.5rem;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.dashboard-item h3,
.dashboard-item .count {
    font-size: 1.3rem;
    font-weight: 550;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    text-align: center;
}
.dashboard-item .count {
    font-size: 2.2rem;
}
@media screen and (max-width: 767px) {
    .DashBoardWrapper {
        margin-right: 1rem;
        padding: 0.5rem;
    }
    .dashboard-items {
        padding-bottom: 2rem;
    }
    .dashboard-item {
        text-align: center;
        width: 100%;
        padding: 1rem 0.3rem;
        margin: 0;
    }
    .dashboard-item h3 {
        font-size: 1.3rem;
    }
}
.filter {
    display: flex;
    gap: 1rem;
    margin: 1.5rem 0;
}
.form-control {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.form-control label {
    font-weight: 550;
}
.form-control input {
    text-indent: 0.2rem;
}
.filter button {
    cursor: pointer;
    background-color: #2e3346;

    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    /* height: fit-content; */
    font-size: 1rem;
    padding: 0.3rem 0.5rem;
    color: white;
    outline: none;
    border: none;
    margin-left: 0.2rem;
}
